<template>
  <v-container fluid
               class="pa-0"
               style="width: 100vw !important;">
    <loading-box v-if="MachineServiceHistories == null"
                 :loading="true" />
    <v-container v-else
                 fluid
                 class="pa-0">

      <!-- Open jobs -->
      <template v-if="openJobs && openJobs.length > 0">
        <h4 class="mx-3 mt-3">{{$t("text.openjobs") }}</h4>
        <v-sheet v-for="item in openJobs"
                 :key="item.Id"
                 class="ma-3">
          <v-card class="text-left rounded-0">
            <v-toolbar color="primary"
                       dark
                       dense
                       elevation="1"
                       class="mb-3">
              <v-toolbar-title>
                {{ getOrderIdText(item) }}
              </v-toolbar-title>
              <v-spacer />
              {{ item.JobTypeName }}
            </v-toolbar>

            <v-card-subtitle class="mt-0 pt-0 mb-0 pb-0">
              {{ $t("text.registered") }}:
              {{ new Date(item.CreatedDate).toLocaleDateString() }}
            </v-card-subtitle>
            <v-card-subtitle class="mt-0 pt-0 mb-0 pb-0">{{ $t("text.status") }}: {{ item.StatusName }}
            </v-card-subtitle>
            <v-card-text>{{ $t("text.error_description") }}:
              {{ item.CustomerComment }}</v-card-text>
            <v-card-text v-if="item.InvoiceComment">{{ $t("text.comment") }}: {{ item.InvoiceComment }}</v-card-text>
            <v-data-table :headers="partsHeader"
                          :items="item.MachineServiceHistoriesParts"
                          disable-filtering
                          disable-pagination
                          disable-sort
                          hide-default-footer
                          v-if="
              item.MachineServiceHistoriesParts &&
              item.MachineServiceHistoriesParts.length > 0
            ">
              <template v-slot:top>
                <v-divider />
              </template>
            </v-data-table>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary"
                     outlined
                     @click="sendOpenJobEvent(item.ParentId)">{{
              $t("text.open")
            }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-sheet>
      </template>

      <!-- Closed jobs -->
      <v-divider class="my-2" />
      <h4 class="mx-3">{{ $t("text.history") }}</h4>
      <v-sheet v-for="item in closedJobs"
               :key="item.Id"
               class="ma-3">
        <v-card :rounded="false"
                class="text-left">
          <v-card-title>
            {{ item.Job.DisplayName }}
            <v-spacer />
            {{ new Date(item.CreatedDate).toLocaleDateString() }}
          </v-card-title>
          <v-card-subtitle>{{ item.Job.StatusName }}</v-card-subtitle>
          <v-card-text>{{ $t("text.error_description") }}:
            {{ item.Job.CustomerComment }}</v-card-text>
          <v-card-text>{{ $t("text.comment") }}:
            {{ item.Job.InvoiceComment }}</v-card-text>
          <v-card-text v-if="item.MachineServiceHistoriesParts">
            <v-divider />
            <v-data-table :headers="partsHeader"
                          :items="item.MachineServiceHistoriesParts"
                          disable-filtering
                          disable-pagination
                          disable-sort
                          hide-default-footer>
            </v-data-table>
          </v-card-text>
          <v-card-actions v-if="item.ServiceReport">
            <v-spacer />
            <v-btn color="primary"
                   @click="sendOpenServiceReportEvent('/api2/resource?relativePath=' + encodeURI(item.ServiceReport))">
              <v-icon>mdi-file-pdf-box</v-icon>
              <v-divider vertical
                         class="mx-1" />
              {{ $t("text.service_report") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-sheet>
    </v-container>
    <v-snackbar v-model="snackbar"
                color="primary">
      {{ snackbarMessage }}
    </v-snackbar>
  </v-container>
</template>


<script>
import http from "@/api/client";
import LoadingBox from "@/components/LoadingBox.vue";

export default {
  name: "CustomerMachineServicePanel",
  components: {
    "loading-box": LoadingBox,
  },

  data() {
    return {
      snackbar: false,
      snackbarMessage: null,
      openJobs: null,
      loading: false,
      partsHeader: [
        {
          text: this.$t("text.article"),
          value: "Article.ArticleDescription",
        },
        {
          text: this.$t("text.amount"),
          value: "ArticleQtyOrdered",
        },
        {
          text: this.$t("text.price"),
          value: "ArticlePriceOut",
        },
      ],
      MachineServiceHistories: null,
    };
  },

  mounted() {
    this.updateServiceHistories();
  },

  methods: {
    async sendOpenJobEvent(parentId) {
      var pj = await this.getParentJob(parentId);
      console.log("openJobEvent", pj);
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler("openJobEvent", pj);
      }
    },

    async sendOpenServiceReportEvent(something) {
      console.log("openServiceReportEvent", something);
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler(
          "openServiceReportEvent",
          something
        );
      }
    },

    async getParentJob(parentId) {
      this.loading = true;
      var parentjob = await http()
        .get("api2/customer/jobs/" + parentId)
        .then((response) => {
          return response.data;
        });
      this.loading = false;
      return parentjob;
    },

    updateServiceHistories() {
      if (!this.machineId) {
        return;
      }
      this.loading = true;
      http()
        .get("api2/customer/machines/history/" + this.machineId)
        .then((response) => {
          this.MachineServiceHistories = response.data;
          this.loading = false;
        });

      http()
        .get("api2/customer/jobs/openmachinejobs/" + this.machineId)
        .then((response) => {
          this.openJobs = response.data;
        });
    },

    getOrderIdText(machinejob) {
      if (machinejob.Name.includes("-")) {
        var parentOrderId = machinejob.Name.substr(
          machinejob.Name.indexOf("-") + 1
        );
        return "Order " + parentOrderId;
      } else if (
        machinejob.Name.includes("S") &&
        machinejob.Name.includes(machinejob.Id)
      ) {
        return "(Internal) Order S" + machinejob.ParentId;
      } else {
        return machinejob.Name;
      }
    },
  },

  computed: {
    machineId() {
      return this.$route.query.machineId;
    },

    closedJobs() {
      if (
        this.MachineServiceHistories != null &&
        this.MachineServiceHistories.length > 0
      ) {
        return this.MachineServiceHistories.filter(
          (e) => e.Job?.StatusId > 30 && e.Job?.StatusId <= 100
        ).sort((a, b) => b.Id - a.Id);
      } else {
        return null;
      }
    },
  },

  watch: {
    machine(val) {
      this.updateServiceHistories(val);
    },
  },
};
</script>


<style scoped>
.highlighted {
  background-color: var(--v-secondary-base) !important;
}
</style>